<template>
    <v-container v-if="isMobile()" class="main-cointainer-mobile">
        <AppLayoutLinksMobile />

        <section class="section-mobile" id="home-mobile" ref="home">
            <HomeViewMobile />
        </section>
        <section class="section-mobile" id="servicios-generales-mobile" ref="servicios-generales">
            <ServiciosViewMobile />
        </section>

        <section class="section-mobile" id="galeria-mobile" ref="galeria">
            <GaleriaViewMobile />
        </section>

        <section class="section-mobile" id="contacto-mobile" ref="contacto">
            <ContactoViewMobile />
        </section>

    </v-container>


    <v-container v-else class="main-cointainer-desktop">


        <AppLayoutLinks />
        <section class="section-desktop" id="home" ref="home">
            <HomeView />
        </section>
        <section class="section-desktop" id="servicios-generales" ref="servicios-generales">
            <ServiciosViewDesktop />
        </section>
        <!-- <section class="section" id="servicios-hogar" ref="servicios-hogar">
            <ServiciosHogarViewDesktop />
        </section>
        <section class="section" id="servicios-industria" ref="servicios-industria">
            <ServiciosIndustriaViewDesktop />
        </section> -->

        <section class="section-desktop" id="clientes" ref="clientes">
            <ClientesViewDesktop />
        </section>

        <section class="contacto-desktop" id="contacto" ref="contacto">
            <ContactoViewDesktop />
        </section>


    </v-container>

</template>
<style>
.container {
    min-width: 100vw !important;
    width: 100vw !important;
    height: 100vh !important;

}

.section-mobile {
    height: 100vh;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
}
.section-desktop {
    height: 100vh;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
}

#servicios-industria {
    background: rgb(251, 248, 248);
    background: radial-gradient(circle, rgba(251, 248, 248, 0.8183648459383753) 0%, rgba(245, 244, 244, 1) 27%, rgba(244, 241, 241, 0.9051995798319328) 55%, rgba(255, 247, 247, 0.9051995798319328) 100%);
}
</style>
<script>

// import ServiciosIndustriaViewDesktop from './ServiciosIndustriaViewDesktop'
// import ServiciosHogarViewDesktop from './ServiciosHogarViewDesktop'
import AppLayoutLinks from '@/layouts/AppLayoutLinks'
import ContactoViewDesktop from './ContactoViewDesktop'
import ClientesViewDesktop from './ClientesViewDesktop'
import HomeView from './HomeView'
import ServiciosViewDesktop from './ServiciosViewDesktop'

import AppLayoutLinksMobile from '@/layouts/mobile/AppLayoutLinksMobile'
import HomeViewMobile from './mobile/HomeViewMobile'
import ServiciosViewMobile from './mobile/ServiciosViewMobile'
import GaleriaViewMobile from './mobile/GaleriaViewMobile'
import ContactoViewMobile from './mobile/ContactoViewMobile'

export default {
    name: 'Main',
    components: {
        HomeView,
        // ServiciosIndustriaViewDesktop,
        // ServiciosHogarViewDesktop,
        ServiciosViewDesktop,
        ClientesViewDesktop,
        ContactoViewDesktop,
        AppLayoutLinks,

        AppLayoutLinksMobile,
        HomeViewMobile,
        ServiciosViewMobile,
        GaleriaViewMobile,
        ContactoViewMobile,
    },
    data() {
        return {


        }
    },
    created() {
        window.addEventListener('scroll', this.handleScroll);



    },
    computed: {

    },
    methods: {

        isMobile() {
            return this.$vuetify.breakpoint.mobile
        }




    }
}
</script>