<template>
    <v-container id="containerservicios-mobile">
        <!-- <v-img id="ingastec-logo-secciones" src="https://ingastecweb.s3.amazonaws.com/ingastec-logo-color-fondo.svg"
            width="8vw" height="auto"></v-img> -->
        <v-row class="ma-0 pa-0" :style="{ height: '10vh', marginBottom:'5vh'}">
            <v-col class="col-12 ma-0 pa-0">
                <h1 id="tituloservicios-mobile" class="ma-0 pa-0"><span class="ma-0 pa-0">PROYECTOS</span></h1>

            </v-col>
            <v-divider></v-divider>
        </v-row>

    

        <v-row class="ma-0 pa-0" :style="{marginLeft:'5%',maxWidth:'90%',marginTop:'10vh'}">
          

          <v-col v-for="(card, i) in cards" :key="i" class="d-flex child-flex ma-0 pa-0" cols="6"
              :style="{margin:'0',padding:'0.3vh'}">
              <v-img :src="card.src" aspect-ratio="1.8" class="grey lighten-2">
                  <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" align="center" justify="center">
                          <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                      </v-row>
                  </template>
              </v-img>
          </v-col>



      </v-row>


    </v-container>


</template>
<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200&display=swap');



#containerserviciosindustria>.row {
    width: 96vw !important;
    margin-left: 2vw;
}

#ingastec-logo-secciones {
    position: absolute;
    left: 10vw;
    margin-top: -4vh;
}

#tituloservicios-mobile {
    font-size: calc(14vmin) !important;
    margin: 0;
    padding: 0;
    color: rgb(0, 128, 54)
}

#tituloservicios-mobile>span {
    display: inline-block;
    border-bottom: 1px solid black;
    /* padding-bottom:1px; */
}

/* span {
    position: relative;
    width: 100%;
    vertical-align: bottom;
    visibility: hidden;
} */

/* .show-title { */


.v-sheet.v-card {
    border-radius: 0;
}

.img-hover-zoom .v-image__image {
    transition: transform 2s ease;

}

.img-hover-zoom:hover .v-image__image {
    transform: scale(1.5);
}

.v-responsive__content {
    flex: content;
    width: 100% !important;
    position: absolute;
    bottom: 0;
}

.titulo-img-servicio {
    visibility: hidden;
}

.titulo-img-servicio-onhover {
    visibility: visible;
    font-family: 'Poppins';
    font-weight: 100;
    color: white;
}


h1,
h2,
h3 {
    color: black;
    font-family: 'Montserrat';
    letter-spacing: 0.05vw;
}

h1 {
    letter-spacing: 0.4vw;
}

.h2-servicios {
    font-size: 4.5vh;
    /* background-color: rgb(123, 238, 123) */
    border-bottom: 0.1rem green solid;
    align-self: flex-start;
    font-style: italic;
    text-align: justify;
    text-justify: inter-word;
}

.h3-servicios {
    font-size: 2.5vh;
    text-align: left;
    font-family: 'Montserrat';
    letter-spacing: 0.1vw;
    text-align: justify;
    text-justify: inter-word;
}

.h3-descripcion-servicios-mobile {
    font-size: 3vh;
    text-align: left;
    font-family: 'Montserrat';
    font-weight: light;
    letter-spacing: 0.1vw;
    text-align: justify;
    text-justify: inter-word;
    margin-right: 1vw;
}

.v-responsive__content {
    margin: 0;
    padding: 0;
}

.v-card__subtitle,
.v-card__text,
.v-card__title {
    margin: 0;
    padding: 0;
    padding-left: 1vw;
}

.col-card-servicios-portrait{
    width: 100vw !important;
    margin-left:10vw !important;
    padding: 0;
    margin: 0;
    margin-bottom:2vh;
    min-height:15vh;
    max-height:15vh !important;
}
.col-card-servicios-landscape{
    width: 100vw !important;
    margin-left:10vw !important;
    padding: 0;
    margin: 0;
    margin-bottom:2vh;
    min-height:17vh;
    max-height:17vh !important;
}
.row-card-servicios-landscape{
    margin-top: 15vh !important;
}
.card-descripcion-servicios-portrait{
   font-size: 3vw;
}
.card-descripcion-servicios-landscape{
   font-size: 3vh;
}

</style>
<script>

export default {
    name: 'GaleriaViewMobile',
    components: {
    },
    data: () => ({
        orientacion:window.screen.orientation.angle,
        cards: [


            { title: 't1-industria', src: 'https://ingastecweb.s3.amazonaws.com/servicios/ingastec-servicio-1.jpg', flex: 6 },
            { title: 't2-industria', src: 'https://ingastecweb.s3.amazonaws.com/servicios/ingastec-servicio-2.jpg', flex: 6 },
            { title: 't3-industria', src: 'https://ingastecweb.s3.amazonaws.com/servicios/ingastec-servicio-3.jpg', flex: 6 },
            { title: 't1-hogar', src: 'https://ingastecweb.s3.amazonaws.com/servicios/ingastec-servicio-4.jpg', flex: 6 },
            { title: 't2-hogar', src: 'https://ingastecweb.s3.amazonaws.com/servicios/ingastec-servicio-5.jpg', flex: 6 },
            { title: 't3-hogar', src: 'https://ingastecweb.s3.amazonaws.com/servicios/ingastec-servicio-6.jpg', flex: 6 },
            { title: 't1-industria', src: 'https://ingastecweb.s3.amazonaws.com/servicios/ingastec-servicio-7.jpg', flex: 6 },
            { title: 't2-industria', src: 'https://ingastecweb.s3.amazonaws.com/servicios/ingastec-servicio-8.jpg', flex: 6 },


        ],
        serviciosgenerales1: [
            {
                nombre: 'Ingeniería',
                img: 'https://ingastecweb.s3.amazonaws.com/servicios/ingastec-industria-1.jpg',
                descripcion: 'Experiencia en diseño de proyectos en sistemas de calefacción central y sanitaria servicio residencial y comercial'
            },
            {
                nombre: 'Servicio Técnico',
                img: 'https://ingastecweb.s3.amazonaws.com/servicios/ingastec-industria-2.jpg',
                descripcion: 'Mantenimiento del sistema de calefacción central. Técnicos y tecnologías especializados altas exigencias de calidad.'
            }
        ],
        serviciosgenerales2: [
        
            {
                nombre: 'Obras y Montaje',
                img: 'https://ingastecweb.s3.amazonaws.com/servicios/ingastec-industria-3.jpg',
                descripcion: 'Ingenieros y técnicos especializados en los proyectos de calefacción, buscando asegurar instalaciones confiables y eficientes'
            },
            {
                nombre: 'Venta repuestos',
                img: 'https://ingastecweb.s3.amazonaws.com/servicios/ingastec-hogar-1.jpg',
                descripcion: 'Venta e instalación de repuestos y accesorios para calderas de calefacción y bombas '
            },
        ]
    }),
    mounted() {



    window.addEventListener("orientationchange", (event) => {

  this.claseColCardServicios(event.target.screen.orientation.angle)
  this.claseRowCardServicios(event.target.screen.orientation.angle)
  this.claseCardDescripcionServicios(event.target.screen.orientation.angle)
  
  
});

  },
    methods: {
        claseTituloImagen(hover) {
            const valor = hover ? 'titulo-img-servicio-onhover' : 'titulo-img-servicio'
            return valor
        },
     
        claseColCardServicios(orientation){

if (orientation === 0 ) {
  this.orientacion=0
  return 'col-card-servicios-portrait'
} else {
  this.orientacion=90
  return 'col-card-servicios-landscape'
}
},
        claseRowCardServicios(orientation){

if (orientation === 0 ) {
  this.orientacion=0
  return 'row-card-servicios-portrait'
} else {
  this.orientacion=90
  return 'row-card-servicios-landscape'
}
},


claseCardDescripcionServicios(orientation){

if (orientation === 0 ) {
  this.orientacion=0
  return 'card-descripcion-servicios-portrait'
} else {
  this.orientacion=90
  return 'card-descripcion-servicios-landscape'
}
},

    }
}
</script>