<template>
    <v-container id="containerclientes-desktop">
        <v-img id="ingastec-logo-secciones-desktop"
            src="https://ingastecweb.s3.amazonaws.com/ingastec-logo-color-fondo-blanco.svg" width="5vw" height="auto"></v-img>
        <v-row class="ma-0 pa-0 row-titulo-seccion-desktop" :style="{ height: '20vh'}">
            <v-col class="col-12 ma-0 pa-0">
                <h1 id="tituloservicios-desktop" class="ma-0 pa-0"><span class="ma-0 pa-0">CLIENTES</span></h1>

            </v-col>
            <v-divider></v-divider>
        </v-row>




        <v-row class="ma-0 pa-0" :style="{ height: '40vh', marginTop: '10vh' }">
            <v-col class="col-12 ma-0 pa-0">
                <v-row align="center" justify="center">
                    <v-col class="col-1"></v-col>
                    <v-col v-for="card in cards" :key="card.title" class="col-img d-flex child-flex" cols="2">




                        <v-hover v-slot="{ hover }">
                            <v-card class="img-hover-zoom">
                                <v-img :src="card.src" class="white--text align-end .img-zoom"
                                    gradient="to bottom, rgba(0,0,0,.05), rgba(0,0,0,.01)" height="20vh">
                                    <v-card-title v-text="card.title.toUpperCase()" :class="claseTituloImagen(hover)" :style="{color:'black'}">
                                    </v-card-title>
                                </v-img>
                            </v-card>
                        </v-hover>


                    </v-col>


                </v-row>
            </v-col>


            <v-row class="ma-0 pa-0" :style="{ width: '100vw', height: '5vh', marginTop:'5vh' }">
            <v-col class="col-12 ma-0 pa-0">
                <p id="p-marcas-representadas">REPRESENTAMOS LAS SIGUIENTES MARCAS</p>
                </v-col>
            </v-row>

            <!-- DESCRIPCION SERVICIOS -->



            <v-col class="col-12 ma-0 pa-0" :style="{ padding: '0', margin: '0' }">
                <v-row class="ma-0 pa-0" :style="{ height: '50%', padding: '0', margin: '0' }">

                    <v-row class="ma-0 pa-0" :style="{ paddingTop: '2vh', margin: '0' }">
                     <v-col class="col-1"></v-col>
                      <v-col v-for="card in marcasrepresentadas" :key="card.title" class="col-img d-flex child-flex" cols="2">




                        <v-hover v-slot="{ hover }">
                            <v-card class="img-hover-zoom">
                                <v-img :src="card.src" class="white--text align-end .img-zoom"
                                    gradient="to bottom, rgba(0,0,0,.05), rgba(0,0,0,.01)" height="20vh">
                                    <v-card-title v-text="card.title.toUpperCase()" :class="claseTituloImagen(hover)">
                                    </v-card-title>
                                </v-img>
                            </v-card>
                        </v-hover>


                    </v-col>
                    </v-row>
                </v-row>

            </v-col>

        </v-row>
    </v-container>


</template>
<style>
#containerclientes-desktop {
    /* background-color: blueviolet; */
    left: 0;
    width: 100vw !important;
    height: 100vh;
    min-height: 100vh;

}

#containerclientes-desktop>.row {
    /* width: 96vw !important;
    margin-left: 2vw; */
}

#ingastec-logo-secciones-desktop {
    position: absolute;
    left: 10vw;
    /* margin-top: -4vh; */
}

/* #tituloservicios {
    font-size: 10vh !important;
    margin: 0;
    padding: 0;
}
#tituloservicios > span {
        display:inline-block;
    border-bottom:1px solid black;

} */
/* span {
    position: relative;
    width: 100%;
    vertical-align: bottom;
    visibility: hidden;
} */

/* .show-title { */


.v-sheet.v-card {
    border-radius: 0;
}

/* .img-hover-zoom {
    cursor: pointer;
} */
.img-hover-zoom .v-image__image {
    transition: transform 2s ease;

}

.img-hover-zoom:hover .v-image__image {
    transform: scale(1.5);
}

.v-responsive__content {
    flex: content;
    width: 100% !important;
    position: absolute;
    bottom: 0;
}

.titulo-img-servicio {
    visibility: hidden;
    
}

.titulo-img-servicio-onhover {
    visibility: visible;
    font-family: 'Poppins';
    font-weight: bold;
    color: black;
}


h1,
h2,
h3 {
    color: black;
    font-family: 'Montserrat';
}

h1 {
    letter-spacing: 0.4vw;
}
#p-marcas-representadas{
    font-family: 'Raleway';
    font-size: 3vh;
    font-weight: bold;
    letter-spacing: 0.1vw;
    color:black;
}
</style>
<script>

export default {
    name: 'ClientesViewDesktop',
    components: {
    },
    data: () => ({
        cards: [
            { title: 'Arkimar', 
            src: 'https://ingastecweb.s3.amazonaws.com/img-clientes/cliente-arkimar.png', 
            web:'https://www.arkimar.cl',
            flex: 6 },
            { title: 'Cerro Alto', src: 'https://ingastecweb.s3.amazonaws.com/img-clientes/cliente-cerroalto.png', flex: 6 },
            { title: 'Copefrut', src: 'https://ingastecweb.s3.amazonaws.com/img-clientes/cliente-copefrut.png', flex: 6 },
            { title: 'San Pedro', src: 'https://ingastecweb.s3.amazonaws.com/img-clientes/cliente-sanpedro.png', flex: 6 },
            { title: 'Solfrut', src: 'https://ingastecweb.s3.amazonaws.com/img-clientes/cliente-solfrut.png', flex: 6 },
            // { title: 't6-hogar', src: 'https://ingastecweb.s3.amazonaws.com/img-clientes/cliente-arkimar.png', flex: 6 },
        ],
        marcasrepresentadas: [
            { title: 'Recal', src: 'https://ingastecweb.s3.amazonaws.com/img-representados/representado-recal.png', flex: 6 },
            { title: 'Anwo', src: 'https://ingastecweb.s3.amazonaws.com/img-representados/representado-anwo.png', flex: 6 },
            { title: 'Cosmoplas', src: 'https://ingastecweb.s3.amazonaws.com/img-representados/representado-cosmoplas.png', flex: 6 },
            { title: 'Toyotomi', src: 'https://ingastecweb.s3.amazonaws.com/img-representados/representado-toyotomi.png', flex: 6 },
            { title: 'Uniclima', src: 'https://ingastecweb.s3.amazonaws.com/img-representados/representado-uniclima.png', flex: 6 },
            // { title: 't6-hogar', src: 'https://ingastecweb.s3.amazonaws.com/img-representados/representado-arkimar.png', flex: 6 },
        ],
    }),
    methods: {
        claseTituloImagen(hover) {
            const valor = hover ? 'titulo-img-servicio-onhover' : 'titulo-img-servicio'
            return valor
        },
        enlaceWeb(enlace){
            ////console.log(enlace)
            window.open(enlace, '_blank').focus();
        }
    }
}
</script>