<template>
  <div id="divfootbuttons" :class="mostrarFootButtons ? 'showfootbuttons' : 'hiddenfootbuttons'">

    <v-row>
      <v-col class="d-flex child-flex" cols="3">

        <v-btn key="ingastec-email" fab large dark bottom left class="v-btn--example" href='mailto:contacto@ingastec.cl'
          target="_blank" :color="colorbotones">
          <v-icon>mdi-email</v-icon>
        </v-btn>

      </v-col>


      <v-col class="d-flex child-flex" cols="3">

<v-btn key="ingastec-phone" fab large dark bottom left class="v-btn--example" @click="toCall()"
  :color="colorbotones">
  <v-icon>mdi-phone</v-icon>
</v-btn>

</v-col>



      <v-col v-for="button in buttons" :key="button.title" class="d-flex child-flex" cols="3">

        <v-btn :key="button.icon" fab large dark bottom left class="v-btn--example" :href=button.link target="_blank"
          :color="colorbotones">
          <v-icon>{{ button.icon }}</v-icon>
        </v-btn>

      </v-col>
    </v-row>
  </div>
</template>

<script>
// import {mapGetters} from "vuex";
import { mapState} from 'pinia'
import { ClassesStore } from '@/store/modules/classes'
export default {
  name: "AppLayoutFootButtons",
  data: () => ({
    buttons: [
      { title: 'ingastec-whatsapp', icon: 'mdi-whatsapp', link: 'https://wa.me/56985787013?text=Hola%20Ingastec', flex: 6 },
      { title: 'ingastec-instagram', icon: 'mdi-instagram', link: 'https://instagram.com/_u/ingastec_eirl/', flex: 6 },
    ],
    colorbotones: 'rgba(0,128,54,0.9)',//'rgba(125,217,89,1)'
    // mostrarFootButtons: true,
  }),
  created() {
    window.addEventListener('scroll', this.handleScroll_foot);
    



  },
            computed:{
// ...mapState({mostrarFootButtons: state => state.classes.mostrarFootButtons}),
// ...mapGetters({mostrarFootButtons:'devuelvemostrarFootButtons'})
...mapState(ClassesStore,{mostrarFootButtons:store=>store.mostrarFootButtons})
    },
  methods: {
    handleScroll_foot() {
      ////console.log("mostrarFootButtons",this.mostrarFootButtons)
      // let sectioncontacto = document.getElementById('contacto')
      // let top = window.scrollY;
      // let offset = sectioncontacto.offsetTop - 400;
      // let height = sectioncontacto.offsetHeight;

      // window.onscroll = () => {

      //   //console.log("seccion ",top)
      //   if (top >= offset && top < offset + height) {

      //     this.mostrarFootButtons = false

      //   }
      //   else {
      //     this.mostrarFootButtons = true
      //   }

      // };

    },
    toCall(){
      window.open('tel:+56985787013')
    }

  }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap');

#divfootbuttons {
  position: fixed;
  padding: 0;
  margin: 0;
  bottom: 5vh;
  right: 5vw;
  /* z-index: 999; */

  /* width: 15vw;
    height: 7vh;
    background-color: brown; */
}

.showfootbuttons {
  transform: scale(100%);
  transition: 1s all ease;
}

.hiddenfootbuttons {
  visibility: hidden !important;
  transform: scale(0);
  transition: 1s all ease;
}

/* #imagenheader{
  position: relative;
  left:0;
  top:10vh;
  height: 20vh;
  background-image: url('../assets/img/logo/imagenes/aa.png');
  background-size: contain;
  z-index: 10;
}
#nav {
  padding-top: 2vh;
  margin-bottom: 6vh;
  padding-right: 1vw;
  width: 100vw !important;
  background-color: #fafcfb;
  opacity: 0.6;
  display: inline-flex;
  align-items: flex-end;
  justify-content: flex-end
  
}
#nav a {
  font-weight: bold;
  color: #091d01;
  padding-right: 2vw;
  text-decoration: none;
  font-family: 'Poppins', sans-serif;
  letter-spacing: 2px;
}
#nav a.router-link-exact-active {
  color: #929292;
} */
</style>