<template>
    <v-container id="containerservicios-mobile">
        <!-- <v-img id="ingastec-logo-secciones" src="https://ingastecweb.s3.amazonaws.com/ingastec-logo-color-fondo.svg"
            width="8vw" height="auto"></v-img> -->
        <v-row class="ma-0 pa-0" :style="{ height: '10vh', marginBottom:'5vh'}">
            <v-col class="col-12 ma-0 pa-0" >
                <h1 id="tituloservicios-mobile" class="ma-0 pa-0"><span class="ma-0 pa-0">SERVICIOS</span></h1>

            </v-col>
            <v-divider></v-divider>
        </v-row>

        <!-- <v-row class="ma-0 pa-0":style="{height:'80vh', marginTop: '10vh',zIndex:'-4',backgroundColor:'rgba(200,200,200,0.1)'}"> -->
            <v-row class="ma-0 pa-0" :class="claseRowCardServicios(orientacion)" :style="{zIndex:'-5',backgroundColor:'rgba(0,0,0,0.1)',marginTop:'10vh !important'}">
            <!-- <v-col class="ma-0 pa-0 col-12">
                <v-row class="ma-0 pa-0" :style="{height:'100%'}"> -->
                    <!-- <v-col class="col-12 ma-0 pa-0 mb-2" :style="{ padding: '0', margin: '0', marginBottom:'5vh',zIndex:'-4'}"
                        v-for="servicio in serviciosgenerales1.concat(serviciosgenerales2)" :key="servicio.nombre">
                        <v-card width="90%" height="100%" min-height="100%" :style="{zIndex:'1',marginLeft:'5%'}">
               

                                <v-card-title class="white--text ml-4" :style="{backgroundColor:'rgba(0,128,54,0.8)'}">
                                    <h3 class="ma-0 pa-0" :style="{color:'white',fontSize:'4vh'}">{{servicio.nombre}}
                                    </h3>
                                </v-card-title>
                       

                            <v-card-text >
                                <div class="font-weight-bold ma-0 ml-8 pa-0 mr-2">
                                    <h3 align="start" class="mt-2  ml-2 h3-descripcion-servicios-mobile">
                                        {{servicio.descripcion}}</h3>
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-col> -->

                    <v-col class="col-10 ma-0 pa-0 mb-2 card card-servicio" :class="claseColCardServicios(orientacion)" 
                        v-for="servicio in serviciosgenerales1.concat(serviciosgenerales2)" :key="servicio.nombre">


                        <div class="card__content">


                            <h3 class="card__heading">{{servicio.nombre}}</h3>
                            <p class="card__descripcion" :class="claseCardDescripcionServicios(orientacion)">{{servicio.descripcion}}</p>

                        </div>

                    </v-col>


                <!-- </v-row>
            </v-col> -->

            <!-- <v-col class="ma-0 pa-0 col-6" >

            
                    <v-carousel cycle show-arrows-on-hover class="ma-0 pa-0" width="100%" height="100%">


                        <v-carousel-item v-for="(card, i) in cards" :key="i" class="ma-0 pa-0">
                            <v-hover v-slot="{ hover }">
                                <v-card class="img-hover-zoom ma-0 pa-0">
                                    <v-img :src="card.src" class="white--text align-end .img-zoom"
                                        gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)" height="60vh">
                                        <v-card-title v-text="card.title.toUpperCase()"
                                            :class="claseTituloImagen(hover)">
                                        </v-card-title>
                                    </v-img>
                                </v-card>
                            </v-hover>
                        </v-carousel-item>
                    </v-carousel>

            </v-col> -->

            <!-- <v-col class="ma-0 pa-0 col-3" :style="{zIndex:'-1'}">
                <v-row class="ma-0 pa-0" :style="{height:'100%'}">
                    <v-col class="col-12 ma-0 pa-0 mb-2" :style="{ padding: '0', margin: '0', marginBottom:'1vh'}"
                        v-for="servicio in serviciosgenerales2" :key="servicio.nombre">
                        <v-card width="90%" height="100%" min-height="100%">
                            <v-img height="20vh" :src=servicio.img>

                                <v-card-title class="white--text ml-4" :style="{backgroundColor:'rgba(0,128,54,0.8)'}">
                                    <h3 class="ma-0 pa-0" :style="{color:'white',fontSize:'4vh'}">{{servicio.nombre}}
                                    </h3>
                                </v-card-title>
                            </v-img>

                            <v-card-text class="ml-">
                                <div class="font-weight-bold ma-0 ml-8 pa-0 mr-2">
                                    <h3 align="start" class="mt-2  ml-2 h3-descripcion-servicios">
                                        {{servicio.descripcion}}</h3>
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col> -->


        </v-row>

        <!-- <v-row class="ma-0 pa-0" :style="{marginLeft:'5%',maxWidth:'90%',marginTop:'1vh'}">
          

          <v-col v-for="(card, i) in cards" :key="i" class="d-flex child-flex ma-0 pa-0" cols="3"
              :style="{margin:'0',padding:'0.3vh'}">
              <v-img :src="card.src" aspect-ratio="1.8" class="grey lighten-2">
                  <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" align="center" justify="center">
                          <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                      </v-row>
                  </template>
              </v-img>
          </v-col>



      </v-row> -->


    </v-container>


</template>
<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200&display=swap');

:root {
  --polka-bg-image:
    radial-gradient(rgb(117, 225, 124) 14.4%, transparent 22%),
    radial-gradient(rgb(117, 225, 124) 14.4%, transparent 22%);
  --polka-bg-position: 0px 0px, 24px 24px;
  --polka-bg-size: 48px 48px;
  --polka-bg-color: rgb(0, 0, 0);
}

#containerservicios-mobile {
 
    left: 0;
    width: 100vw !important;
    height: 10vh;
    min-height: 10vh;
    z-index: -5;

    background-image: 
    radial-gradient(rgb(117, 225, 124) 14.4%, transparent 22%),
    radial-gradient(rgb(117, 225, 124) 14.4%, transparent 22%);
  background-position: 0px 0px, 24px 24px;
  background-size:auto;
  background-color: rgb(0, 0, 0);
  

}

#containerserviciosindustria>.row {
    width: 96vw !important;
    margin-left: 2vw;
}

#ingastec-logo-secciones {
    position: absolute;
    left: 10vw;
    margin-top: -4vh;
}

#tituloservicios-mobile {
    font-size: calc(14vmin) !important;
    margin: 0;
    padding: 0;
    margin-top: 5vh !important;

    color: rgb(0, 128, 54)
}

#tituloservicios-mobile>span {
    display: inline-block;
    border-bottom: 1px solid black;
    /* padding-bottom:1px; */
}

/* span {
    position: relative;
    width: 100%;
    vertical-align: bottom;
    visibility: hidden;
} */

/* .show-title { */


.v-sheet.v-card {
    border-radius: 0;
}

.img-hover-zoom .v-image__image {
    transition: transform 2s ease;

}

.img-hover-zoom:hover .v-image__image {
    transform: scale(1.5);
}

.v-responsive__content {
    flex: content;
    width: 100% !important;
    position: absolute;
    bottom: 0;
}

.titulo-img-servicio {
    visibility: hidden;
}

.titulo-img-servicio-onhover {
    visibility: visible;
    font-family: 'Poppins';
    font-weight: 100;
    color: white;
}


h1,
h2,
h3 {
    color: black;
    font-family: 'Montserrat';
    letter-spacing: 0.05vw;
}

h1 {
    letter-spacing: 0.4vw;
}

.h2-servicios {
    font-size: 4.5vh;
    /* background-color: rgb(123, 238, 123) */
    border-bottom: 0.1rem green solid;
    align-self: flex-start;
    font-style: italic;
    text-align: justify;
    text-justify: inter-word;
}

.h3-servicios {
    font-size: 2.5vh;
    text-align: left;
    font-family: 'Montserrat';
    letter-spacing: 0.1vw;
    text-align: justify;
    text-justify: inter-word;
}

.h3-descripcion-servicios-mobile {
    font-size: 3vh;
    text-align: left;
    font-family: 'Montserrat';
    font-weight: light;
    letter-spacing: 0.1vw;
    text-align: justify;
    text-justify: inter-word;
    margin-right: 1vw;
}

.v-responsive__content {
    margin: 0;
    padding: 0;
}

.v-card__subtitle,
.v-card__text,
.v-card__title {
    margin: 0;
    padding: 0;
    padding-left: 1vw;
}

.col-card-servicios-portrait{
    width: 100vw !important;
    margin-left:10vw !important;
    padding: 0;
    margin: 0;
    margin-bottom:2vh;
    min-height:15vh;
    max-height:15vh !important;
}
.col-card-servicios-landscape{
    width: 100vw !important;
    margin-left:10vw !important;
    padding: 0;
    margin: 0;
    margin-bottom:2vh;
    min-height:17vh;
    max-height:17vh !important;
}
.row-card-servicios-landscape{
    margin-top: 15vh !important;
}
.card-descripcion-servicios-portrait{
   font-size: 3vw;
}
.card-descripcion-servicios-landscape{
   font-size: 3vh;
}

</style>
<script>

export default {
    name: 'ServiciosViewMobile',
    components: {
    },
    data: () => ({
        orientacion:window.screen.orientation.angle,
        cards: [
            { title: 't1-industria', src: 'https://ingastecweb.s3.amazonaws.com/servicios/ingastec-industria-1.jpg', flex: 6 },
            { title: 't2-industria', src: 'https://ingastecweb.s3.amazonaws.com/servicios/ingastec-industria-2.jpg', flex: 6 },
            { title: 't3-industria', src: 'https://ingastecweb.s3.amazonaws.com/servicios/ingastec-industria-3.jpg', flex: 6 },
            { title: 't1-hogar', src: 'https://ingastecweb.s3.amazonaws.com/servicios/ingastec-hogar-1.jpg', flex: 6 },
            { title: 't2-hogar', src: 'https://ingastecweb.s3.amazonaws.com/servicios/ingastec-hogar-2.jpg', flex: 6 },
            { title: 't3-hogar', src: 'https://ingastecweb.s3.amazonaws.com/servicios/ingastec-hogar-3.jpg', flex: 6 },
        ],
        serviciosgenerales1: [
            {
                nombre: 'Ingeniería',
                img: 'https://ingastecweb.s3.amazonaws.com/servicios/ingastec-industria-1.jpg',
                descripcion: 'Experiencia en diseño de proyectos en sistemas de calefacción central y sanitaria servicio residencial y comercial'
            },
            {
                nombre: 'Servicio Técnico',
                img: 'https://ingastecweb.s3.amazonaws.com/servicios/ingastec-industria-2.jpg',
                descripcion: 'Mantenimiento del sistema de calefacción central. Técnicos y tecnologías especializados altas exigencias de calidad.'
            }
        ],
        serviciosgenerales2: [
        
            {
                nombre: 'Obras y Montaje',
                img: 'https://ingastecweb.s3.amazonaws.com/servicios/ingastec-industria-3.jpg',
                descripcion: 'Ingenieros y técnicos especializados en los proyectos de calefacción, buscando asegurar instalaciones confiables y eficientes'
            },
            {
                nombre: 'Venta repuestos',
                img: 'https://ingastecweb.s3.amazonaws.com/servicios/ingastec-hogar-1.jpg',
                descripcion: 'Venta e instalación de repuestos y accesorios para calderas de calefacción y bombas '
            },
        ]
    }),
    mounted() {



    window.addEventListener("orientationchange", (event) => {

  this.claseColCardServicios(event.target.screen.orientation.angle)
  this.claseRowCardServicios(event.target.screen.orientation.angle)
  this.claseCardDescripcionServicios(event.target.screen.orientation.angle)
  
  
});

  },
    methods: {
        claseTituloImagen(hover) {
            const valor = hover ? 'titulo-img-servicio-onhover' : 'titulo-img-servicio'
            return valor
        },
     
        claseColCardServicios(orientation){

if (orientation === 0 ) {
  this.orientacion=0
  return 'col-card-servicios-portrait'
} else {
  this.orientacion=90
  return 'col-card-servicios-landscape'
}
},
        claseRowCardServicios(orientation){

if (orientation === 0 ) {
  this.orientacion=0
  return 'row-card-servicios-portrait'
} else {
  this.orientacion=90
  return 'row-card-servicios-landscape'
}
},


claseCardDescripcionServicios(orientation){

if (orientation === 0 ) {
  this.orientacion=0
  return 'card-descripcion-servicios-portrait'
} else {
  this.orientacion=90
  return 'card-descripcion-servicios-landscape'
}
},

    }
}
</script>