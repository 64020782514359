<template>
  <div>

    <nav role="navigation">
            <div id="menuToggle">
              <input type="checkbox" v-model="checkbox"/>
                <span></span>
                <span></span>
                <span></span>
            <ul id="menu">
              <li><a class="active" @click="Goto('home-mobile')">INICIO</a></li>
              <li><a @click="Goto('servicios-generales-mobile')">SERVICIOS</a></li>
              <li><a @click="Goto('galeria-mobile')">GALERIA</a></li>
              <li><a @click="Goto('contacto-mobile')">CONTACTO</a></li>
            </ul>
           </div>
          </nav>
          
  </div>
</template>

<script>
export default {
  name: "AppLayoutLinksMobile",
  data: () => ({
    drawer: false,
    group: null,
    checkbox: false,
  }),
  methods: {
    Goto(seccion) {
      ////console.log(seccion)
      ////console.log("El checkbox",this.checkbox)
      this.checkbox=false
      if (document.getElementById(seccion)) {
        document.getElementById(seccion).scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
      }
    }
  }
}

</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap');

#imagenheader {
  position: relative;
  left: 0;
  top: 10vh;
  height: 10vh;
  background-image: url('../../assets/img/logo/imagenes/aa.png');
  background-size: contain;
  z-index: 10;
}

a {
  text-decoration: none;
  color: rgb(0, 128, 54);
  opacity:1;
  font-family: 'Montserrat', sans serif;
  font-size: 1.5em;
  font-weight: bold;
  transition: 200ms;
}


a:hover {
  opacity:0.5;
}
ul {
  padding: 0;
  
}
nav {
  background-color: #1E1E23;
  height: 65px;

}


#menuToggle {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 5vh;
  left: 25px;
  z-index: 1;
  -webkit-user-select: none;
  user-select: none;
}

#menuToggle input
{
  display: flex;
  width: 40px;
  height: 32px;
  position: absolute;
  cursor: pointer;
  opacity: 0;
  z-index: 2;
}

#menuToggle span
{
  display: flex;
  width: 29px;
  height: 2px;
  margin-bottom: 5px;
  position: relative;
  background: #ffffff;
  border-radius: 3px;
  z-index: 1;
  transform-origin: 5px 0px;
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              opacity 0.55s ease;
}

#menuToggle span:first-child
{
  transform-origin: 0% 0%;
}

#menuToggle span:nth-last-child(2)
{
  transform-origin: 0% 100%;
}

#menuToggle input:checked ~ span
{
  opacity: 1;
  transform: rotate(45deg) translate(-3px, -1px);
  background: #36383F;
}
#menuToggle input:checked ~ span:nth-last-child(3)
{
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

#menuToggle input:checked ~ span:nth-last-child(2)
{
  transform: rotate(-45deg) translate(0, -1px);
}

#menu
{
  position: absolute;
  width: 70vw;
  height: 400px;
  box-shadow: 0 0 10px #85888C;
  opacity: 0.8;
  margin: -50px 0 0 -50px;
  padding: 50px;
  padding-top: 100px;
  background-color: #F5F6FA;
  -webkit-font-smoothing: antialiased;
  transform-origin: 0% 0%;
  transform: translate(-100%, 0);
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
}

#menu li
{
  padding: 10px 0;
  transition-delay: 2s;
}

#menuToggle input:checked ~ ul
{
  transform: none;
}

/* .div-lista-menu{
  display: inline-flexbox;
}



#nav-mobile {
  position: fixed;
  top: 0;
  left: 0;
  padding: 0 !important;

  margin-bottom: 1vh;

  width: 100vw !important;
  opacity: 0.6;
  display: inline-flex;
  align-items: flex-end;
  justify-content: flex-end
}

#nav a {
  font-weight: bold;
  color: #091d01;
  padding-right: 2vw;
  text-decoration: none;
  font-family: 'Poppins', sans-serif;
  letter-spacing: 2px;
  cursor: pointer;
}

#nav a.router-link-exact-active {
  color: #929292;
} */
</style>