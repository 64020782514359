<template>
    <v-container id="containercontacto-mobile">

        <video-background src='https://ingastecweb.s3.amazonaws.com/video-contacto-1.mp4'
            style="max-height:100vh; height: 100vh; width:100vw ;max-width: 100vw;" class="video-contacto-mobile">
        </video-background>

        <!-- <v-img id="ingastec-logo-secciones" src="https://ingastecweb.s3.amazonaws.com/ingastec-logo-color-fondo.svg"
            width="8vw" height="auto"></v-img> -->
        <v-row class="ma-0 pa-0" align="center" justify="center" :style="{ height: '8vh', marginTop: '2vh', marginBottom: '10vh'}">
            <v-col class="col-12 ma-0 pa-0" align="center" justify="center">
                <v-img  align="center" justify="center" src="https://ingastecweb.s3.amazonaws.com/ingastec-logo-color-fondo.svg"
            height="auto" :style="{zIndex:'99'}" :class="claseImagenContacto(orientacion)"></v-img>

                <!-- <h1 id="tituloservicios" class="ma-0 pa-0 text-contacto"><span class="ma-0 pa-0 text-contacto">INGASTEC</span></h1> -->

            </v-col>
            <v-divider></v-divider>
        </v-row>



        <!-- <v-row class="ma-0 pa-0" :class="mostrardivscontacto ? 'showdivscontacto' : 'hiddendivscontacto'" :style="{ height: '20vh', marginTop: '15vh', paddingBottom: '12vh', backgroundColor: 'rgba(200,200,200,0.01)' }"> -->
            <!-- <v-row class="ma-0 pa-0" :class="mostrardivscontacto ? 'showdivscontacto' : 'hiddendivscontacto'"> -->
                <v-row class="ma-0 pa-0">
            <v-col class="col-12 ma-0 pa-0">
                <h2 class="text-contacto" :class="claseH2Contacto(orientacion)" @click="toCall()">
                    <v-icon large color="rgba(0,128,54,0.9)" :style="{ fontSize: 'calc(6vmin)' }">mdi-phone</v-icon> +56
                    98 5787013
                </h2>
            </v-col>
            <v-col class="col-12 ma-0 pa-0">
                <!-- <h2 id="texto_correo" class="text-contacto" :class="claseH2Contacto(orientacion)" @click="copy(), snackbar = true"> -->
                    <h2 id="texto_correo" class="text-contacto" :class="claseH2Contacto(orientacion)">
                    <v-icon large color="rgba(0,128,54,0.9)" :style="{ fontSize: 'calc(6vmin)' }">mdi-email</v-icon>
                    contacto@ingastec.cl
                </h2>
                <span class="tooltiptext">Click para copiar correo</span>
            </v-col>
        </v-row>


        <v-snackbar v-model="snackbar" :timeout="timeout" color="green">
            {{ text }}

            <template v-slot:action="{ attrs }">
                <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
                    Cerrar
                </v-btn>
            </template>
        </v-snackbar>


        <v-row class="ma-0 pa-0 mediabuttons" :class="claseRowButtonsContacto(orientacion)">
            <v-col class="col-3 ma-0 pa-0"></v-col>
            <v-col v-for="button in buttons" :key="button.title" class="d-flex child-flex" cols="3">

                <v-btn :key="button.icon" fab large dark bottom left
                    :class="mostrardivscontacto ? 'showdivscontacto' : 'hiddendivscontacto'" :href=button.link
                    target="_blank" :color="colorbotones" height="calc(15vmin)" width="calc(15vmin)">
                    <v-icon :style="{ fontSize: 'calc(10vmin)' }">{{ button.icon }}</v-icon>
                </v-btn>

            </v-col>
        </v-row>

        <v-row class="ma-0 pa-0"
            :style="{ height: '20vh', marginTop: '5vh', backgroundColor: 'rgba(100,100,100,0.2)' }">
            <v-col class="col-12 ma-0 pa-0">
                <h3 class="text-contacto" :class="claseH3Contacto(orientacion)" >CRISTIAN AILLAPAN QUINTEROS
                    </h3>
                    <h3 class="text-contacto" :class="claseH3Contacto(orientacion)" >INSTALADOR CERTIFICADO
                    SEC</h3>
            </v-col>
            <v-col class="col-12 ma-0 pa-0">
                <h4 class="text-contacto">INGASTEC CALEFACCION</h4>
                <h4 class="text-contacto">CURICO, REGION DEL MAULE | CHILE </h4>
            </v-col>
        </v-row>



    </v-container>


</template>
<style>
#containercontacto-mobile {
    /* background-color: blueviolet; */
    left: 0;
    width: 100vw !important;
    height: 50vh;
    min-height: 50vh;

}

.video-contacto-mobile {
    position: absolute !important;
    left: 0;
    z-index: -2;
    opacity: 1;
}

/* .contenedorvideo {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background-image: url('../assets/img/backgroundllama.svg');
    background-size: cover;
    z-index: -1;
} */

/* h1,
h2,
h3,
h4 {
    color: black;
    font-family: 'Montserrat';
} */

.text-contacto {
    color: white;
}

.contact-h2-portrait {
    font-size: 6vw;
    letter-spacing: 0.4vw;
}
.contact-h2-landscape {
    font-size: 8vh;
    letter-spacing: 0.4vw;
}

.contact-h3-portrait {
    font-size: 5vw;
    letter-spacing: 0.4vw;
}
.contact-h3-landscape {
    font-size: 6vh;
    letter-spacing: 0.4vw;
}
.row-buttons-contact-portrait{
    margin-top: 20vh;
    margin-bottom: 0vh;
}
.row-buttons-contact-landscape{
    margin-top: 0vh;
    margin-bottom: 0vh;
    display: none;
}


.mediabuttons::before {
    transform: scale(0%);
    transition: 1s all ease;
}

.mediabuttons::after {
    transform: scale(100%);
    transition: 1s all ease;
}

.showdivscontacto {
    transform: scale(100%);
    transition: 1s all ease;
}

.hiddendivscontacto {
    visibility: hidden !important;
    transform: scale(0);
    transition: 1s all ease;
}

#texto_correo:hover {
    cursor: pointer;
}

.tooltiptext {
    visibility: hidden;
    width: 200px;
    background-color: green;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;

    /* Position the tooltip */
    /* position: absolute; */
    right: 35vw;
    /* top: 10vh; */
    z-index: 1;
}

#texto_correo:hover+.tooltiptext {
    visibility: visible;
}
.v-snack__wrapper.theme--dark{
    background-color: green;
}
.img-contacto-orientation-portrait{
  width:20vw;
  margin-top: 1vh;
}
.img-contacto-orientation-landscape{
  width:20vh;
  margin-top: 5vh;
}
</style>
<script>
// import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
// import {mapState } from "vuex";
// import { mapStores } from 'pinia'
import { mapState, mapActions } from 'pinia'
import { ClassesStore } from '@/store/modules/classes'
import VideoBackground from 'vue-responsive-video-background-player'
export default {
    name: 'ContactoViewDesktop',
    components: {
        VideoBackground,
    },
    data: () => ({
        buttons: [
            { title: 'ingastec-whatsapp', icon: 'mdi-whatsapp', link: 'https://wa.me/56985787013?text=Hola%20Ingastec', flex: 6 },
            { title: 'ingastec-instagram', icon: 'mdi-instagram', link: 'https://instagram.com/_u/ingastec_eirl/', flex: 6 },
        ],
        colorbotones: 'rgba(0,128,54,0.9)',//'rgba(125,217,89,1)'
        mostrardivscontacto: false,
        snackbar: false,
        text: 'Email copiado',
        timeout: 2000,
        orientacion:window.screen.orientation.angle

    }),
    created() {
        window.addEventListener('scroll', this.handleScroll);
        window.addEventListener('load', this.resetinContact);
        // this.counterStore
        this.muestrastore()
        // this.resetinContact()

    },
    mounted() {


window.addEventListener("orientationchange", (event) => {

this.claseImagenContacto(event.target.screen.orientation.angle)
this.claseH2Contacto(event.target.screen.orientation.angle)
this.claseH3Contacto(event.target.screen.orientation.angle)
this.claseRowButtonsContacto(event.target.screen.orientation.angle)

});

},
    computed: {
        // ...mapState({mostrarFootButtons: state => state.classes.mostrarFootButtons}),
        // ...mapStores()
        ...mapState(ClassesStore, { mostrarFootButtons: store => store.mostrarFootButtons }),

    },

    methods: {
        ...mapActions(ClassesStore, ['cambiamostrarFootButtons']),
        muestrastore() {
            ////console.log(this.mostrarFootButtons)

        },
        resetinContact() {

            ////console.log("reseteando en contacto")
            let sectioncontacto = document.getElementById('contacto-mobile')
            let top = window.scrollY;
            let offset = sectioncontacto.offsetTop - 400;
            let height = sectioncontacto.offsetHeight;
            if (top >= offset && top < offset + height) {
                // ////console.log("en seccion contacto")
                this.mostrardivscontacto = true
                // this.mostrarFootButtons = false
                this.cambiamostrarFootButtons(false)


            }
            else {
                this.mostrardivscontacto = false
                //   this.mostrarFootButtons = true
                this.cambiamostrarFootButtons(true)

            }


        },
        claseTituloImagen(hover) {
            const valor = hover ? 'titulo-img-servicio-onhover' : 'titulo-img-servicio'
            return valor
        },
        handleScroll() {
            let sectioncontacto = document.getElementById('contacto-mobile')
            let top = window.scrollY;
            let offset = sectioncontacto.offsetTop - 400;
            let height = sectioncontacto.offsetHeight;

            window.onscroll = () => {

                ////console.log("method in contact")
                ////console.log("this.mostrardivscontacto", this.mostrardivscontacto)
                ////console.log("this.mostrarFootButtons", this.mostrarFootButtons)
                if (top >= offset && top < offset + height) {
                    // ////console.log("en seccion contacto")
                    this.mostrardivscontacto = true
                    // this.mostrarFootButtons = false
                    this.cambiamostrarFootButtons(false)


                }
                else {
                    this.mostrardivscontacto = false
                    //   this.mostrarFootButtons = true
                    this.cambiamostrarFootButtons(true)

                }

            };

        },
        copy() {
            const copyText = document.getElementById('texto_correo').textContent
            navigator.clipboard.writeText(copyText);
        },

        claseImagenContacto(orientation){
      if (orientation === 0 ) {
        this.orientacion=0
        return 'img-contacto-orientation-portrait'
      } else {
        this.orientacion=90
        return 'img-contacto-orientation-landscape'
      }
    },

    claseH2Contacto(orientation){
      if (orientation === 0 ) {
        // this.orientacion=0
        return 'contact-h2-portrait'
      } else {
        // this.orientacion=90
        return 'contact-h2-landscape'
      }
    },

    claseH3Contacto(orientation){
      if (orientation === 0 ) {
        // this.orientacion=0
        return 'contact-h3-portrait'
      } else {
        // this.orientacion=90
        return 'contact-h3-landscape'
      }
    },
    claseRowButtonsContacto(orientation){
      if (orientation === 0 ) {
        // this.orientacion=0
        return 'row-buttons-contact-portrait'
      } else {
        // this.orientacion=90
        return 'row-buttons-contact-landscape'
      }
    },
    toCall(){
      window.open('tel:+56985787013')
    }
    

    }
}
</script>