import { render, staticRenderFns } from "./AppLayoutFootButtonsMobile.vue?vue&type=template&id=498f107d&scoped=true&"
import script from "./AppLayoutFootButtonsMobile.vue?vue&type=script&lang=js&"
export * from "./AppLayoutFootButtonsMobile.vue?vue&type=script&lang=js&"
import style0 from "./AppLayoutFootButtonsMobile.vue?vue&type=style&index=0&id=498f107d&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "498f107d",
  null
  
)

export default component.exports