<template>


<v-row id="rowcontenidohome-desktop" align="center" justify="center">

  <v-col id="col_logo" class="col-12 ma-0 pa-0" align="center" :style="{ marginTop: '20vh'}">
      <v-img src="https://ingastecweb.s3.amazonaws.com/ingastec-logo-color.svg" width="30vw" height="auto">
      </v-img>
    </v-col>


    <!-- <v-col class="coltituloshome col-6 ma-0 pa-0" align="end" justify="end">
      <v-row  align="end" justify="end">
        <v-col class="col-12" align="right" justify="right">
            <h2 class="h2tituloshome" >CALEFACCION</h2>

          </v-col>
          <v-col class="col-12 mb-12" align="right" justify="right" ></v-col>
          <v-col class="col-12">
            <h2 class="h2tituloshome">CURICÓ</h2>
          </v-col>
      </v-row>
    </v-col> -->


    
    <video-background src='https://ingastecweb.s3.amazonaws.com/a-edited.mp4'
      style="max-height:102vh; height: 102vh; width:100vw ;max-width: 100vw;" class="video-home">
    </video-background>
    <AppLayoutFootButtons></AppLayoutFootButtons>
    
</v-row>










</template>
<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200&display=swap');

/* .seccionsitio {
  min-width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
} */
/* #home{
  position: absolute;
  left:0;
  top:0;
} */
#rowcontenidohome-desktop {
  height: 100vh;
  width: 100vw !important;
  /* margin-top: 12vh; */
  background-color: rgba(231, 231, 231,0.1);
  /* background-color: rgb(0, 255, 42); */
}

.video-home {
  position: absolute !important;
  left: 0;
  top: 0;
  z-index: -2;
}

/* .container {
  margin: 0;
  padding: 0;
} */

.contenedorvideo {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  /* height: 100%; */
  background-image: url('../assets/img/backgroundllama.svg');
  background-size: cover;
  z-index: -1;
}

h1 {
  font-family: 'Poppins', sans-serif;
  font-size: calc(20vmin);
}

h2 {
  font-family: 'Poppins', sans-serif;
  font-size: calc(8vmin);
}


.coltituloshome {
  /* background-color: rgba(231, 231, 231,0.01); */
  align-items: center;
  vertical-align: middle;
}

.h2tituloshome {
  color: white;
  letter-spacing: 0.1vw;
  font-size: 10vh !important;
  font-family: 'Montserrat', sans-serif;
  text-align: right;
  justify-items: end;
}
</style>
<script>

import VideoBackground from 'vue-responsive-video-background-player'
import AppLayoutFootButtons from '@/layouts/AppLayoutFootButtons'


export default {
  name: 'Home',
  components: {

    VideoBackground,
    AppLayoutFootButtons

  },
  data() {
    return {
      sourcevideo: 'https://drive.google.com/file/d/13yVS_FsKPRdtBi2pR4UBbmLHRkrlS3iX/view',

    }
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);



  },
  computed: {
    styleDiv() {
      return {
        height: '40vh',
        //backgroundColor:'rgba(200,100,100,1)',
        backgroundImage: "https://drive.google.com/file/d/177DYVq6ijYyMqhZtVYkFL_9tQiHqffU8/view"
      }
    }
  },
  methods: {
    sourceVideo() {
      return '@/assets/img/videos/a.mp4'
    },
    handleScroll() {
      let section = document.querySelectorAll('section');
      let menu = document.querySelectorAll('header nav a');
      window.onscroll = () => {
        section.forEach(i => {
          let top = window.scrollY;
          let offset = i.offsetTop - 150;
          let height = i.offsetHeight;
          let id = i.getAttribute('id');
          if (top >= offset && top < offset + height) {
            menu.forEach(link => {
              link.classList.remove('active');
              document.querySelector('header nav a[href*=' + id + ']')
                .classList.add('active');
            });
          }
        });
      };

    },//



  }
}
</script>