<template>


<v-row id="rowcontenidohome-mobile" align="center" justify="center">

  <v-col id="col_logo" class="col-12 ma-0 pa-0" align="center" :style="{}">
      <v-img src="https://ingastecweb.s3.amazonaws.com/ingastec-logo-color.svg" height="auto" :class="claseImagenHome(orientacion)">
      </v-img>
    </v-col>


   


    
    <video-background src='https://ingastecweb.s3.amazonaws.com/a-edited.mp4'
      style="max-height:100vh; height: 100vh; width:100vw ;max-width: 100vw;" class="video-home-mobile">
    </video-background>
    <AppLayoutFootButtonsMobile></AppLayoutFootButtonsMobile>
    
</v-row>










</template>
<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200&display=swap');

/* .seccionsitio {
  min-width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
} */
/* #home{
  position: absolute;
  left:0;
  top:0;
} */
#rowcontenidohome-mobile {
  height: 100vh !important;
  width: 100vw !important;
  margin:0;
  /* background-color: rgba(231, 231, 231,0.1); */
  /* background-color: rgba(0, 0, 231,1); */

}

.video-home-mobile {
  position: absolute !important;
  left: 0;
  top: 65px;
  z-index: -2;
}

/* .container {
  margin: 0;
  padding: 0;
} */

.contenedorvideo {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-image: url('../../assets/img/backgroundllama.svg');
  background-size: cover;
  z-index: -1;
}

h1 {
  font-family: 'Poppins', sans-serif;
  font-size: calc(20vmin);
}

h2 {
  font-family: 'Poppins', sans-serif;
  font-size: calc(8vmin);
}


.coltituloshome {
  /* background-color: rgba(231, 231, 231,0.01); */
  align-items: center;
  vertical-align: middle;
}

.h2tituloshome {
  color: white;
  letter-spacing: 0.1vw;
  font-size: 10vh !important;
  font-family: 'Montserrat', sans-serif;
  text-align: right;
  justify-items: end;
}
.img-orientation-portrait{
  width:80vw;
  margin-top: 20vh;
}
.img-orientation-landscape{
  width:80vh;
  margin-top: 0vh;
}
</style>
<script>

import VideoBackground from 'vue-responsive-video-background-player'
import AppLayoutFootButtonsMobile from '@/layouts/mobile/AppLayoutFootButtonsMobile'


export default {
  name: 'HomeViewMobile',
  components: {

    VideoBackground,
    AppLayoutFootButtonsMobile

  },
  data() {
    return {
      sourcevideo: 'https://drive.google.com/file/d/13yVS_FsKPRdtBi2pR4UBbmLHRkrlS3iX/view',
      orientacion:window.screen.orientation.angle

    }
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);



  },

  mounted() {
    // window.addEventListener(
    //   "orientationchange",
    //   this.claseImagenHome()
    // );

    window.addEventListener("orientationchange", (event) => {
  // ////console.log(`the orientation of the device is now ${event.target.screen.orientation.angle}`);
  this.claseImagenHome(event.target.screen.orientation.angle)
});

  },
  computed: {
    styleDiv() {
      return {
        height: '40vh',
        //backgroundColor:'rgba(200,100,100,1)',
        backgroundImage: "https://drive.google.com/file/d/177DYVq6ijYyMqhZtVYkFL_9tQiHqffU8/view"
      }
    }
  },
  methods: {
    sourceVideo() {
      return '@/assets/img/videos/a.mp4'
    },
    claseImagenHome(orientation){
      ////console.log("reconoce orientacion")
      ////console.log(orientation)
      // const orientation = window.screen.orientation.type
      if (orientation === 0 ) {
        this.orientacion=0
        return 'img-orientation-portrait'
      } else {
        this.orientacion=90
        return 'img-orientation-landscape'
      }
    },
    handleScroll() {
      let section = document.querySelectorAll('section');
      let menu = document.querySelectorAll('header nav a');
      window.onscroll = () => {
        section.forEach(i => {
          let top = window.scrollY;
          let offset = i.offsetTop - 150;
          let height = i.offsetHeight;
          let id = i.getAttribute('id');
          if (top >= offset && top < offset + height) {
            menu.forEach(link => {
              link.classList.remove('active');
              document.querySelector('header nav a[href*=' + id + ']')
                .classList.add('active');
            });
          }
        });
      };

    },//



  }
}
</script>